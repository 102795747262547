import { useEffect, useState } from 'react';

/**
 * Adds a script tag to the document and returns its
 * load/error states
 *
 * NOTE: You may want to modify this if you don't want
 * async and defer attributes to be true
 */
function useScript(src: string, crossOrigin?: boolean, location = 'body') {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    // create script
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    /* istanbul ignore else: empty else branch */
    if (crossOrigin) {
      script.crossOrigin = 'anonymous';
    }

    const onScriptLoad = () => {
      setLoaded(true);
      setHasError(false);
    };

    const onScriptError = () => {
      setHasError(true);
      setLoaded(true);
    };

    // add event listeners
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);

    const targetElement = location === 'head' ? document.head : document.body;

    // append to dom
    /* istanbul ignore else: empty else clause */
    if (typeof window !== 'undefined') {
      targetElement.appendChild(script);
    }

    // remove event listeners on clean up
    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
      targetElement.removeChild(script);
    };
    // should update only when src changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return [loaded, hasError];
}

export default useScript;
