import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Grab a query parameter from the URL bar
 *
 * Note the param will always initially be `undefined`
 *
 * @param paramName The name of the parameter, as used in the URL
 */
export default function useQueryParam(paramName: string): string | undefined {
  const [param, setParam] = useState<string | undefined>(undefined);
  const router = useRouter();
  useEffect(() => {
    const urlParam = router.query[paramName];
    if (typeof urlParam === 'string') setParam(urlParam);
  }, [router.query, setParam, paramName]);
  return param;
}
