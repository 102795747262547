import styled, { keyframes } from 'styled-components';

const bounceDelay = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0.75);
  }

  40% {
    transform: scale(1);
  }
`;

export const SVG = styled.svg`
  display: inline;
  fill: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: inherit;
  stroke: inherit;
  transform: translate3d(0, 0, 0);
  vertical-align: middle;
`;

export const Circle = styled.circle`
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
  backface-visibility: hidden;
  background-color: currentColor;
  border-radius: 100%;
  display: inline-block;
  fill: currentColor;
  height: 0.3em;
  stroke: transparent;
  stroke-linecap: round;
  transform-origin: center center;
  width: 0.3em;
  will-change: transform;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;
