import React from 'react';
import * as S from './styles';

const Loader = ({ className }: { className?: string }) => (
  <S.SVG className={className} viewBox="0 0 16 16" data-testid="loader">
    <S.Circle cx="2" cy="8" r="2" />
    <S.Circle cx="8" cy="8" r="2" />
    <S.Circle cx="14" cy="8" r="2" />
  </S.SVG>
);

export default Loader;
