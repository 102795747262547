import React from 'react';
import styled from 'styled-components';

export type BoxSpacing = 's1' | 's2' | 's3' | 's4' | 's5' | 's6' | 's8' | 's10' | 's12' | 's16';

export type BoxBackgroundColors =
  | 'neutral99'
  | 'primary99'
  | 'primary20'
  | 'neutral95'
  | 'secondary20';

interface BoxProps {
  children?: React.ReactNode;
  spacing?: BoxSpacing;
  $horizontal?: boolean;
  $background?: BoxBackgroundColors;
  $padding?: BoxSpacing;
  $flex?: number;
}

const Box = styled.div<BoxProps>`
  background-color: ${props =>
    (props.$background && props.theme.co[props.$background]) || 'transparent'};
  flex: ${props => props.$flex || 1};
  padding: ${props => props.theme.sz[props.$padding || 's0']};
  ${props =>
    props.$horizontal
      ? `
        margin-right: ${props.theme.sz[props.spacing || 's0']};
    `
      : `
        margin-bottom: ${props.theme.sz[props.spacing || 's0']};
    `};
  &:last-child {
    margin: 0;
  }
`;

export default Box;
