import styled, { css } from 'styled-components';

export const OuterWrapper = styled.div<{
  $displayContents?: boolean;
}>(
  ({ $displayContents }) => css`
    // We use contents when this wrapper element should be ignored
    display: ${$displayContents ? 'contents' : 'block'};
  `,
);

export const Wrapper = styled.div<{
  $tooltipStyle?: 'normal' | 'hint';
  $fullWidth?: boolean;
}>(
  ({ theme, $tooltipStyle, $fullWidth }) => css`
    background-color: ${$tooltipStyle === 'normal' ? theme.co.secondary20 : theme.co.neutral99};
    border-radius: ${theme.bo.small};
    color: ${$tooltipStyle === 'normal' ? theme.co.neutral99 : theme.co.secondary0};
    font-size: ${theme.DEPRECATED_ty.small};
    max-width: ${!$fullWidth && theme.sz.s48};
    font-weight: ${theme.DEPRECATED_ty.mediumWeight};
    // the z-index that brings the tooltip above the LearningSessionHeader
    z-index: ${theme.zi.z10};
    word-wrap: break-word;
    cursor: default;

    ${$tooltipStyle === 'hint'
      ? css`
          padding: ${theme.sz.s6};

          max-width: min(${theme.sz.xs}, 100%);
          box-shadow: ${theme.sh.s1};
          -webkit-box-shadow: 0 ${theme.sz.s1} ${theme.sz.s2} rgba(0, 0, 0, 0.2);
          font-weight: ${theme.DEPRECATED_ty.mediumWeight};
        `
      : css`
          padding: ${theme.sz.s2} ${theme.sz.s4};
        `}
  `,
);
