import { addCustomScrollbar } from 'src/utils/addCustomScrollbar';
import styled, { DefaultTheme, css } from 'styled-components';

export interface PopoutWrapperProps {
  $isDarkTheme?: boolean;
  $noBackdrop?: boolean;
  $positionX: 'left' | 'center' | 'right';
  $positionY?: 'top' | 'center' | 'bottom';
}

export const getPositionX = (theme: DefaultTheme, positionX: PopoutWrapperProps['$positionX']) => {
  if (positionX === 'left')
    return css`
      right: -${theme.sz.s3};
    `;
  if (positionX === 'right')
    return css`
      left: -${theme.sz.s3};
    `;
  /* istanbul ignore else: empty else branch */
  if (positionX === 'center')
    return css`
      left: 50%;
      transform: translateX(-50%);
    `;
};

export const getPositionY = (theme: DefaultTheme, positionY: PopoutWrapperProps['$positionY']) => {
  if (positionY === 'top')
    return css`
      bottom: calc(100% + ${theme.sz.s2});
    `;
  if (positionY === 'center')
    return css`
      top: -${theme.sz.s7};
    `;
  if (positionY === 'bottom')
    return css`
      top: calc(100% + ${theme.sz.s2});
    `;
};

export const PopoutWrapper = styled.div<PopoutWrapperProps>(
  ({ theme, $isDarkTheme, $noBackdrop, $positionY, $positionX }) => css`
    border: ${$isDarkTheme || $noBackdrop
      ? 'none'
      : `${theme.sz.s05} solid ${theme.co.secondary90}`};
    border-radius: ${$isDarkTheme ? theme.sz.s2 : theme.sz.s4};
    background: ${$isDarkTheme ? theme.co.secondary10 : theme.co.neutral99};
    box-shadow: ${$isDarkTheme &&
    `${theme.sz.s05} ${theme.sz.s05} ${theme.sz.s5} ${theme.co.secondary10}`};
    box-shadow: ${$noBackdrop && `0 0 ${theme.sz.s1} ${theme.sz.s05} ${theme.co.neutral90}`};
    position: absolute;
    z-index: ${theme.zi.modal};
    overflow: hidden;
    ${getPositionY(theme, $positionY)};
    ${getPositionX(theme, $positionX)};

    @media (max-width: ${theme.sz.maxMobileBreakpoint}) {
      ${$noBackdrop
        ? css`
            max-width: 238px;
          `
        : css`
            top: ${theme.sz.s16};
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            right: unset;
            max-width: 100vw;
          `}
    }
  `,
);

// This wrapper is necessary so the scrollbar is cut off by the border radius of the PopoutWrapper
export const ScrollWrapper = styled.div<{ $limitHeight?: boolean }>(
  ({ theme, $limitHeight }) => css`
    overflow: hidden;
    ${$limitHeight &&
    css`
      max-height: 75vh;
      overflow-y: auto;
    `}

    ${addCustomScrollbar(theme)};
  `,
);

export const PopoutBackdrop = styled.div<{ $noBackdrop: boolean }>(
  ({ theme, $noBackdrop }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.zi.header};

    ${!$noBackdrop &&
    css`
      @media (max-width: ${theme.sz.maxMobileBreakpoint}) {
        background-color: ${theme.co.secondary0};
        opacity: 0.8;
      }
    `}
  `,
);

export const PopoutParentContainer = styled.div(
  () => css`
    position: relative;
  `,
);
