import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import * as S from './styles';

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';
type Trigger = 'click' | 'right-click' | 'hover' | 'focus';

type TooltipProps = {
  children: ReactNode;
  tooltip: ReactNode;
  trigger?: Trigger | Trigger[] | null;
  disabled?: boolean;
  placement?: Placement;
  forceShow?: boolean;
  tooltipStyle?: 'normal' | 'hint';
  fullWidth?: boolean;
  displayContents?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltip,
  trigger = 'hover',
  placement = 'auto',
  disabled = false,
  forceShow,
  tooltipStyle = 'normal',
  fullWidth,
  displayContents,
  ...props
}) => {
  const [controlledVisible, setControlledVisible] = useState<boolean | undefined>(
    disabled ? false : undefined,
  );
  const parentWrapperRef = useRef<HTMLDivElement>(null);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      ...props,
      trigger,
      placement,
      offset: [0, 8],
      visible: forceShow === undefined ? controlledVisible : forceShow,
      onVisibleChange: setControlledVisible,
    });

  useEffect(() => {
    if (disabled) {
      // If the component transitions to disabled set visible to false
      setControlledVisible(false);
    }
  }, [disabled]);

  useEffect(() => {
    const wrapper = parentWrapperRef?.current?.childNodes[0] as HTMLDivElement;
    /* istanbul ignore next: hard to mock react-popper-tooltip, tested manually */
    if (wrapper) {
      // Removes `Popper: CSS "margin"` warning: https://github.com/react-bootstrap/react-bootstrap/issues/5081
      // while preserving its other default styles
      wrapper.style.margin = '0';
    }
  }, [parentWrapperRef]);

  return (
    <S.OuterWrapper $displayContents={displayContents}>
      <span ref={setTriggerRef}>{children}</span>
      {visible && !disabled && (
        <div ref={parentWrapperRef}>
          <S.Wrapper
            ref={setTooltipRef}
            $tooltipStyle={tooltipStyle}
            {...getTooltipProps({ className: 'tooltip-container' })}
            $fullWidth={fullWidth}
          >
            {tooltip}
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          </S.Wrapper>
        </div>
      )}
    </S.OuterWrapper>
  );
};
