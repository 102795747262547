import { useState, useEffect, useCallback } from 'react';

export const DEFAULT_COUNTDOWN_TICK_INTERVAL = 200;

const defaultOptions = {
  intervalInMs: DEFAULT_COUNTDOWN_TICK_INTERVAL,
  disabled: false,
};

/**
 * Creates a timer and uses it to return the
 * percentageTimeLeft
 */
function useCountdownTimer(options: {
  durationInMs: number;
  intervalInMs?: number;
  disabled?: boolean;
}) {
  const { durationInMs, intervalInMs, disabled } = {
    ...defaultOptions,
    ...options,
  };

  const [currentTime, setCurrentTime] = useState<number>(durationInMs);
  const [isRunning, setIsRunning] = useState(!disabled);

  const resetTimer = useCallback(
    () => setCurrentTime(durationInMs),
    [durationInMs, setCurrentTime],
  );
  const pauseTimer = useCallback(() => setIsRunning(false), [setIsRunning]);
  const restartTimer = useCallback(() => {
    resetTimer();
    setIsRunning(true);
  }, [setIsRunning, resetTimer]);

  useEffect(() => {
    if (disabled) {
      return;
    }
    function tick() {
      if (isRunning) {
        setCurrentTime(t => t - intervalInMs);
      }
    }
    const id = setInterval(tick, intervalInMs);
    return () => clearInterval(id);
  }, [setCurrentTime, isRunning, intervalInMs, disabled]);

  // return value as a percentage
  return {
    percentageTimeLeft: (currentTime / durationInMs) * 100,
    timeLeftMs: currentTime,
    restartTimer: disabled ? () => null : restartTimer,
    resetTimer: disabled ? () => null : resetTimer,
    pauseTimer: disabled ? () => null : pauseTimer,
  };
}

export default useCountdownTimer;
