import React, { FC, KeyboardEvent } from 'react';
import Link from 'next/link';
import { IconName } from '..';
import { getIconFromCode } from './icons/icons';
import * as S from './styles';

type DropdownImageItem = {
  type: 'image';
  imageSrc: string;
  labelText: string;
  highlight?: boolean;
  onClick?: () => void;
  theme?: 'light' | 'dark';
};

type DropdownIconItem = {
  type: 'icon';
  iconCode: IconName;
  labelText: string;
  highlight?: boolean;
  onClick?: () => void;
  theme?: 'light' | 'dark';
  isIconOnRight?: boolean;
  fontSize?: string;
  fontWeight?: string;
};

export type DropdownLabelItem = {
  type: 'label';
  labelText: string;
  description?: string;
  highlight?: boolean;
  onClick?: () => void;
  theme?: 'light' | 'dark';
};

type DropdownLinkItem = {
  type: 'link';
  linkText: string;
  href: string;
  newTab?: boolean;
  onClick?: () => void;
  theme?: 'light' | 'dark';
  highlight?: boolean;
};

type DropdownElementItem = {
  type: 'element';
  element: React.ReactElement;
  theme?: 'light' | 'dark';
  onClick?: () => void;
};

export type DropdownItemProps =
  | DropdownImageItem
  | DropdownIconItem
  | DropdownLabelItem
  | DropdownLinkItem
  | DropdownElementItem;

const DropdownItem: FC<DropdownItemProps> = props => {
  const onKeyup = (event: KeyboardEvent<HTMLLIElement | HTMLAnchorElement>) => {
    const { onClick } = props;

    if (event.key === 'Enter' || event.key === ' ') {
      onClick && onClick();
    }
  };

  switch (props.type) {
    case 'element':
      return props.element;
    case 'icon':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          onKeyUp={onKeyup}
          $isIconOnRight={props.isIconOnRight}
          role="option"
          id={`default_select_namespace_element_${props.labelText}`}
          tabIndex={0}
        >
          {getIconFromCode(props.iconCode)}
          <S.DropdownItemLabel
            $isDarkTheme={props.theme === 'dark'}
            $highlight={props.highlight}
            $fontSize={props.fontSize}
            $fontWeight={props.fontWeight}
          >
            {props.labelText}
          </S.DropdownItemLabel>
        </S.DropdownItemWrapper>
      );
    case 'label':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          onKeyUp={onKeyup}
          role="option"
          id={`default_select_namespace_element_${props.labelText}`}
          tabIndex={0}
        >
          <S.DropdownItemLabel $isDarkTheme={props.theme === 'dark'} $highlight={props.highlight}>
            {props.labelText}
            {props.description && (
              <S.Description data-testid="dropdownLabelDescription">
                {props.description}
              </S.Description>
            )}
          </S.DropdownItemLabel>
        </S.DropdownItemWrapper>
      );
    case 'image':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          onKeyUp={onKeyup}
          role="option"
          id={`default_select_namespace_element_${props.labelText}`}
          tabIndex={0}
        >
          <S.DropdownImageIcon src={props.imageSrc} alt="" />
          <S.DropdownItemLabel $isDarkTheme={props.theme === 'dark'} $highlight={props.highlight}>
            {props.labelText}
          </S.DropdownItemLabel>
        </S.DropdownItemWrapper>
      );
    case 'link':
      return (
        <S.DropdownItemWrapper
          onClick={props.onClick}
          role="option"
          id={`default_select_namespace_element_${props.linkText}`}
        >
          <Link href={props.href} passHref legacyBehavior>
            <S.StyledLink
              {...(props.newTab ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
              $highlight={props.highlight}
            >
              {props.linkText}
            </S.StyledLink>
          </Link>
        </S.DropdownItemWrapper>
      );
  }
};

export default DropdownItem;
