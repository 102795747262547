import React from 'react';
import { IconName } from 'src/components';
import styled from 'styled-components';
import Icon from '../../Icon';

const StyledIcon = styled(Icon)`
  width: ${props => props.theme.sz.s8};
  height: ${props => props.theme.sz.s8};
  margin-right: ${props => props.theme.sz.s2};

  svg {
    width: ${props => props.theme.sz.s8};
    height: ${props => props.theme.sz.s8};
  }
`;

export const getIconFromCode = (iconCode: IconName) => <StyledIcon name={iconCode} />;
