import React, { ComponentProps, FC, ReactNode, useState } from 'react';
import { DropdownMenu, Popout, PopoutParentContainer, Stack, Tooltip } from 'src/components';
import Icon from '../Icon';
import * as S from './styles';

interface HeaderDropdownIconProps
  extends Pick<ComponentProps<typeof DropdownMenu>, 'dropdownSections'> {
  imageSrc?: string;
  iconName?: ComponentProps<typeof Icon>['name'];
  position?: 'left' | 'center' | 'right';
  tooltipText?: string | JSX.Element | ReactNode;
  showCarat?: boolean;
  titleText?: string;
  useCurrentColor?: boolean;
  isPremium?: boolean;
  ariaText?: string;
}

const HeaderDropdownIcon: FC<HeaderDropdownIconProps> = ({
  imageSrc,
  iconName,
  dropdownSections,
  position = 'left',
  tooltipText,
  showCarat,
  titleText,
  useCurrentColor,
  isPremium,
  ariaText,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const profileIcon = iconName === 'profileIcon';

  return (
    <S.HeaderDropdownIconWrapper>
      {titleText && <S.Title onClick={() => setShowDropdown(!showDropdown)}>{titleText}</S.Title>}

      <PopoutParentContainer>
        <Tooltip
          placement="bottom"
          tooltip={tooltipText || ''}
          disabled={showDropdown || !tooltipText}
          fullWidth
        >
          <S.HeaderDropdownButton
            $isProfileIcon={profileIcon}
            $isPremium={!!isPremium}
            data-testid={iconName ? `headerDropdownIcon-${iconName}` : 'headerDropdownImage'}
            onClick={() => setShowDropdown(!showDropdown)}
            aria-label={ariaText}
            title={ariaText}
          >
            <Stack $direction="horizontal" $spacing="s2" $align="center">
              {iconName ? (
                <S.StyledIcon
                  $isProfileIcon={profileIcon}
                  name={iconName}
                  useCurrentColor={useCurrentColor}
                />
              ) : (
                <S.HeaderDropdownIconInner $imageSrc={imageSrc} />
              )}
              {showCarat && <S.Caret name="caretDown" />}
            </Stack>
          </S.HeaderDropdownButton>
        </Tooltip>
        <Popout
          show={showDropdown}
          positionX={position}
          limitHeight
          onClickBackdrop={() => setShowDropdown(false)}
        >
          <DropdownMenu
            dropdownSections={dropdownSections}
            onClickItem={() => setShowDropdown(false)}
          />
        </Popout>
      </PopoutParentContainer>
    </S.HeaderDropdownIconWrapper>
  );
};

export default HeaderDropdownIcon;
