import { useState, useEffect, useCallback } from 'react';

function useDelayTimer(durationInMs: number, callback: () => void) {
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    if (isRunning) {
      const id = setTimeout(() => {
        setIsRunning(false);
        callback();
      }, durationInMs);
      return () => clearTimeout(id);
    }
  }, [durationInMs, isRunning, callback, setIsRunning]);

  const restartTimer = useCallback(() => {
    setIsRunning(true);
  }, [setIsRunning]);

  return {
    restartTimer,
  };
}

export default useDelayTimer;
