import React, { FC, useCallback } from 'react';
import DropdownItem, { DropdownItemProps, DropdownLabelItem } from './DropdownItem';
import * as S from './styles';

export interface DropdownSectionProps {
  dropdownItems: DropdownItemProps[];
  title?: string;
}

export type ThemeColor = 'light' | 'dark';

interface DropdownMenuProps {
  dropdownSections: DropdownSectionProps[];
  onClickItem?: () => void;
  theme?: ThemeColor;
  isAlphabetical?: boolean;
  minWidth?: string;
  borderWidth?: string;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  dropdownSections,
  onClickItem = () => {},
  theme,
  isAlphabetical,
  minWidth,
  borderWidth,
}) => {
  const onClick = useCallback(
    (onClick?: () => void) => () => {
      onClick && onClick();
      onClickItem();
    },
    [onClickItem],
  );

  return (
    <S.DropdownWrapper $minWidth={minWidth}>
      {dropdownSections.map(({ title, dropdownItems }, index) => {
        const getDropdownItems = isAlphabetical
          ? dropdownItems.sort((a, b) => {
              const { labelText: aLabelText } = a as DropdownLabelItem;
              const { labelText: bLabelText } = b as DropdownLabelItem;

              return aLabelText.localeCompare(bLabelText);
            })
          : dropdownItems;

        return (
          <S.DropdownSection
            key={`${title}-${index}`} // eslint-disable-line react/no-array-index-key
            data-testid={`dropdownSection-${index}`}
            $borderWidth={borderWidth}
            role="listbox"
            id="default_select_namespace_dropdown"
            tabIndex={-1}
          >
            {title && <S.DropdownSubheading>{title}</S.DropdownSubheading>}
            {getDropdownItems.map((dropdownItem, index) => {
              switch (dropdownItem.type) {
                case 'image':
                  return (
                    <DropdownItem
                      theme={theme}
                      key={`${dropdownItem.type}-${index}`} // eslint-disable-line react/no-array-index-key
                      type={dropdownItem.type}
                      labelText={dropdownItem.labelText}
                      highlight={dropdownItem.highlight}
                      imageSrc={dropdownItem.imageSrc}
                      onClick={onClick(dropdownItem.onClick)}
                    />
                  );
                case 'label':
                  return (
                    <DropdownItem
                      theme={theme}
                      key={`${dropdownItem.type}-${index}`} // eslint-disable-line react/no-array-index-key
                      type={dropdownItem.type}
                      labelText={dropdownItem.labelText}
                      description={dropdownItem.description}
                      highlight={dropdownItem.highlight}
                      onClick={onClick(dropdownItem.onClick)}
                    />
                  );
                case 'icon':
                  return (
                    <DropdownItem
                      theme={theme}
                      key={`${dropdownItem.type}-${index}`} // eslint-disable-line react/no-array-index-key
                      type={dropdownItem.type}
                      labelText={dropdownItem.labelText}
                      iconCode={dropdownItem.iconCode}
                      highlight={dropdownItem.highlight}
                      onClick={onClick(dropdownItem.onClick)}
                      isIconOnRight={dropdownItem.isIconOnRight}
                      fontSize={dropdownItem.fontSize}
                      fontWeight={dropdownItem.fontWeight}
                    />
                  );
                case 'link':
                  return (
                    <DropdownItem
                      theme={theme}
                      key={`${dropdownItem.type}-${index}`} // eslint-disable-line react/no-array-index-key
                      type={dropdownItem.type}
                      linkText={dropdownItem.linkText}
                      href={dropdownItem.href}
                      newTab={dropdownItem.newTab}
                      onClick={onClick(dropdownItem.onClick)}
                      highlight={dropdownItem.highlight}
                    />
                  );
                default:
                  return (
                    <DropdownItem
                      theme={theme}
                      key={`${dropdownItem.type}-${index}`} // eslint-disable-line react/no-array-index-key
                      type={dropdownItem.type}
                      element={dropdownItem.element}
                    />
                  );
              }
            })}
          </S.DropdownSection>
        );
      })}
    </S.DropdownWrapper>
  );
};
