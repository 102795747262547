import { useEffect, useMemo } from 'react';
import Mousetrap from 'mousetrap';

type Handler = Parameters<typeof Mousetrap.bind>[1];
type MousetrapEvent = Parameters<Handler>[0];
type Combo = Parameters<Handler>[1];

// Object containing key codes that map to key names.
type Code = { [key: number]: string };

// Since the Mousetrap uses the value of the key, not its code,
// in order for hot keys to work on keyboards with a different layout
// must specify the key code and its literal value, which is later used in the code.
const keyCodes: Code = {
  // arrows
  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',

  72: 'h', // left
  74: 'j', // down
  75: 'k', // up
  76: 'l', // right

  67: 'c', // leaderboard
  82: 'r', // play audio/video

  17: 'ctrl',
  91: 'command',
  // Fix for Firefox browser (Windows 10),
  // because for some reason in Cyrillic layout key code of symbol '/' = 190.
  // In all other cases (other browsers and OS) key code of symbol '/' = 191.
  190: '/',
  191: '/',
  13: 'enter',
  32: 'space',
  27: 'esc',
  8: 'backspace',
  // numbers to choose answer
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
};

// expected to only fail in dev, please check you've added key code to the array above
export const checkIsInList = (shortcut: string) =>
  shortcut.split(/[ +]+/).forEach(key => {
    if (!Object.values(keyCodes).includes(key)) {
      throw new Error(`Key '${key}' is not in keyCodes list`);
    }
  });

const checkShortcut = (shortcut: string | string[]) => {
  const shortcutsArray = typeof shortcut === 'string' ? [shortcut] : shortcut;

  shortcutsArray.forEach(key => {
    checkIsInList(key);
  });
};

/**
 * @param shortcut Keys to listen for. Formatted for Mousetrap (https://craig.is/killing/mice).
 * @param handler Function to run when the key is pressed.
 *
 * @see https://craig.is/killing/mice
 */
function useKeyboardShortcut(
  shortcut: string | string[],
  handler?: Handler | (() => void),
  forceKeyDown?: boolean,
) {
  const memoHandler = useMemo(
    () =>
      handler &&
      ((e: MousetrapEvent, combo: Combo) => {
        checkShortcut(shortcut);
        // Only override enter when nothing is focussed
        const focussedTag = document?.activeElement?.tagName;
        if (
          !handler ||
          ((shortcut === 'enter' || shortcut === 'space') &&
            !(focussedTag === 'BODY' || focussedTag === 'HTML' || focussedTag === 'DIV'))
        ) {
          return;
        }
        handler(e, combo);
      }),
    [handler, shortcut],
  );

  useEffect(() => {
    if (memoHandler) {
      Mousetrap.addKeycodes(keyCodes);
      Mousetrap.bind(shortcut, memoHandler, forceKeyDown ? 'keydown' : 'keyup');
    }
    return () => {
      Mousetrap.unbind(shortcut, forceKeyDown ? 'keydown' : 'keyup');
    };
  }, [shortcut, memoHandler, forceKeyDown]);
}

export default useKeyboardShortcut;
