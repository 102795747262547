import React, { FC, ReactNode } from 'react';
import { useKeyboardShortcut } from 'src/hooks';
import * as S from './styles';

export type PositionX = 'left' | 'center' | 'right';
export type PositionY = 'top' | 'center' | 'bottom';

export interface PopoutProps {
  show: boolean;
  /** When true, limits the height and adds scrolling if the content exceeds that size. */
  limitHeight?: boolean;
  positionX: PositionX;
  positionY?: PositionY;
  onClickBackdrop?: () => void;
  children?: ReactNode;
  theme?: 'light' | 'dark';
  noBackdrop?: boolean;
  /** Allows styling with styled-components */
  className?: string;
}

export const PopoutParentContainer = S.PopoutParentContainer;

export const Popout: FC<PopoutProps> & {
  ParentContainer: typeof PopoutParentContainer;
} = ({
  show,
  positionX,
  positionY,
  limitHeight,
  onClickBackdrop,
  children,
  theme,
  noBackdrop = false,
  className,
}) => {
  /* istanbul ignore next: already mocked */
  useKeyboardShortcut('esc', () => onClickBackdrop && onClickBackdrop());

  if (!show) return null;

  return (
    <>
      <S.PopoutWrapper
        $isDarkTheme={theme === 'dark'}
        $noBackdrop={noBackdrop}
        $positionX={positionX}
        $positionY={positionY}
        data-testid="popout"
        className={className}
      >
        <S.ScrollWrapper $limitHeight={limitHeight} data-testid="scroll-wrapper">
          {children}
        </S.ScrollWrapper>
      </S.PopoutWrapper>
      {onClickBackdrop && (
        <S.PopoutBackdrop
          $noBackdrop={noBackdrop}
          onClick={onClickBackdrop}
          data-testid="popout-backdrop"
        />
      )}
    </>
  );
};

Popout.ParentContainer = S.PopoutParentContainer;
