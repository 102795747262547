import { useEffect, useState, useCallback } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

function useWindowSize(): WindowSize {
  const isClient = typeof window === 'object';

  const getSize = useCallback(
    function () {
      return {
        width: isClient
          ? window.innerWidth
          : /* istanbul ignore next: untested branch of code, please test */ 500,
        height: isClient
          ? window.innerHeight
          : /* istanbul ignore next: untested branch of code, please test */ 500,
      };
    },
    [isClient],
  );

  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = useCallback(
    function () {
      setWindowSize(getSize());
    },
    [setWindowSize, getSize],
  );

  useEffect(() => {
    /* istanbul ignore next: untested branch of code, please test */
    if (!isClient) {
      return;
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isClient]);

  return windowSize;
}

export default useWindowSize;
